<script>
import { message } from 'ant-design-vue'
import {
  SsdMobilenetv1Options,
  detectSingleFace,
  detectAllFaces,
  resizeResults,
  nets,
  draw,
  matchDimensions,
} from 'face-api.js'

export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    await this.init()
    await this.initOptions()
    await this.getUserCameraMedia()
    await this.detectorFace()
  },
  beforeDestroy() {
    cancelAnimationFrame(this.requestId)
  },
  methods: {
    async init() {
      await nets.ssdMobilenetv1.loadFromUri('/faceData')
      // await this.getBaiduToken()
    },
    getBaiduToken() {
      return new Promise((resolve, reject) => {
        fetch(
          '/baiduAuth/token?grant_type=client_credentials&client_id=iXQoqa2x0xGKQMWoeylKYvtL&client_secret=GvM0HwOGxA8Da10jpP4bzpKtVfKi1ze9&'
        )
          .then((e) => e.json())
          .then((e) => {
            this.baiduToken = e.access_token
            resolve()
          })
      })
    },
    initOptions() {
      this.options = new SsdMobilenetv1Options({
        minConfidence: 0.9,
      })
    },
    reset() {
      cancelAnimationFrame(this.requestId)
      this.mediaStream.getTracks().forEach((e) => e.stop())
    },
    // 动态人脸检测
    getUserCameraMedia() {
      const constraints = {
        video: {
          // facingMode: 'user',
          width: {
            ideal: this.width,
          },
          height: {
            ideal: this.height,
          },
        },
      }
      try {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.mediaStream = stream
            this.$refs.video.srcObject = stream
            this.$refs.video.play()
          })
          .catch((err) => {
            console.log('报错', err)
            message.error('请连接摄像头以后重新再试')
          })
      } catch (error) {
        message.error('设备不兼容请更换浏览器')
      }
    },
    // 获取视频帧图片
    getVideoFrame(box) {
      const canvas = document.createElement('canvas')
      const video = this.$refs.video
      const ctx = canvas.getContext('2d')
      canvas.width = box.width
      canvas.height = box.height
      ctx.drawImage(video, box.left, box.top, box.right, box.bottom, 0, 0, box.width, box.height)
      return canvas.toDataURL().slice(22)
    },
    isFaceDetectionModelLoaded() {
      return !!nets.ssdMobilenetv1.params
    },
    getBaiduSearch(data) {
      return new Promise((resolve, reject) => {
        const img = this.getVideoFrame(data.box)
        fetch(`/baiduRest/search?access_token=${this.baiduToken}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
          body: JSON.stringify({
            image: img,
            group_id_list: 'farmers',
            image_type: 'BASE64',
          }),
        })
          .then((e) => e.json())
          .then((e) => {
            if (e.result) {
              resolve(e.result)
            } else {
              resolve(null)
            }
          })
      })
    },
    getFaceLabel(face) {
      if (face && face.user_list) {
        if (face.user_list[0].score > 90) {
          return face.user_list[0].user_id + face.user_list[0].score
        } else {
          return '未知'
        }
      } else {
        return '未知'
      }
    },
    drawFace(faces) {
      const canvas = this.$refs.canvas
      const dims = matchDimensions(canvas, this.$refs.video, true)
      faces.forEach((resize) => {
        const options = new draw.DrawBoxOptions({ boxColor: 'red', label: this.getFaceLabel(resize.face) })
        new draw.DrawBox(resize.box, options).draw(this.$refs.canvas)
      })
    },
    postBaiduDetect(result) {
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < result.length; i++) {
          const data = result[i]
          const face = await this.getBaiduSearch(data)
          if (face) {
            data['face'] = face
          }
        }
        resolve(result)
      })
    },
    async detectorFace() {
      if (!this.isFaceDetectionModelLoaded()) {
        this.requestId = requestAnimationFrame(this.detectorFace)
      }
      const result = await detectSingleFace(this.$refs.video, this.options)
      if (result) {
        this.$emit('success', this.getVideoFrame(result.box))
      } else {
        this.$refs.canvas &&
          this.$refs.canvas.getContext('2d').fillRect(0, 0, this.$refs.video.width, this.$refs.video.height)
        this.$emit('fail')
      }
      this.requestId = requestAnimationFrame(this.detectorFace)
    },
    onPlay() {},
    // 人脸识别的四个边框
    renderFaceBox() {
      return <div class="face-box"></div>
    },
  },
  render() {
    return (
      <div class="face-main">
        <video
          autoplay
          muted
          playsinline
          style={{ width: this.width + 'px', height: this.height + 'px' }}
          ref={'video'}
          onloadedmetadata={this.detectorFace}
        />
        <canvas ref={'canvas'} />
        {/* 人脸识别的四个框 */}
        {this.renderFaceBox()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.face-main {
  padding: 30px;
  position: relative;
  video {
    object-fit: fill;
    transform: rotateY(180deg);
  }
  canvas {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
}
.face-box {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('./asset/bk.png');
  background-size: 100% 100%;
}
</style>
